var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"app-clipboard"},[_c('a-page-header',{attrs:{"title":"剪贴板","sub-title":"进入页面自动复制内容到剪贴板"}}),_c('div',{staticClass:"adv-form"},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.form}},[_c('a-form-model-item',{attrs:{"label":"启用","required":"","prop":"enabled","rules":[
            {
              required: true,
              message: '是否启用必填'
            }
          ]}},[_c('a-switch',{model:{value:(_vm.form.enabled),callback:function ($$v) {_vm.$set(_vm.form, "enabled", $$v)},expression:"form.enabled"}})],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"label":"首页","required":"","prop":"homeEnable","rules":[
                {
                  required: true,
                  message: '是否应用于首页必填'
                }
              ]}},[_c('a-switch',{model:{value:(_vm.form.homeEnable),callback:function ($$v) {_vm.$set(_vm.form, "homeEnable", $$v)},expression:"form.homeEnable"}})],1)],1),_c('a-col',{attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"label":"分类页","required":"","prop":"categoryEnable","rules":[
                {
                  required: true,
                  message: '是否应用于分类页必填'
                }
              ]}},[_c('a-switch',{model:{value:(_vm.form.categoryEnable),callback:function ($$v) {_vm.$set(_vm.form, "categoryEnable", $$v)},expression:"form.categoryEnable"}})],1)],1),_c('a-col',{attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"label":"商品列表页","required":"","prop":"productListEnable","rules":[
                {
                  required: true,
                  message: '是否应用于商品列表页必填'
                }
              ]}},[_c('a-switch',{model:{value:(_vm.form.productListEnable),callback:function ($$v) {_vm.$set(_vm.form, "productListEnable", $$v)},expression:"form.productListEnable"}})],1)],1),_c('a-col',{attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"label":"自定义页","required":"","prop":"pageEnable","rules":[
                {
                  required: true,
                  message: '是否应用于自定义页必填'
                }
              ]}},[_c('a-switch',{model:{value:(_vm.form.pageEnable),callback:function ($$v) {_vm.$set(_vm.form, "pageEnable", $$v)},expression:"form.pageEnable"}})],1)],1)],1),_c('a-form-model-item',{attrs:{"label":"文本内容","prop":"text","rules":[
            {
              required: true,
              message: '文本内容必填'
            }
          ]}},[_c('a-textarea',{attrs:{"maxLength":500,"placeholder":"请填写复制的文本内容","auto-size":{ minRows: 4, maxRows: 10 }},model:{value:(_vm.form.text),callback:function ($$v) {_vm.$set(_vm.form, "text", $$v)},expression:"form.text"}})],1)],1),_c('div',{staticClass:"footer"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.submitForm}},[_vm._v(" 保存 ")]),_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":_vm.cancel}},[_vm._v(" 取消 ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }