<template>
  <Layout>
    <div class="app-clipboard">
      <a-page-header title="剪贴板" sub-title="进入页面自动复制内容到剪贴板" />

      <div class="adv-form">
        <a-form-model :model="form" ref="form">
          <a-form-model-item
            label="启用"
            required
            prop="enabled"
            :rules="[
              {
                required: true,
                message: '是否启用必填'
              }
            ]"
          >
            <a-switch v-model="form.enabled" />
          </a-form-model-item>

          <a-row :gutter="16">
            <a-col :span="6">
              <a-form-model-item
                label="首页"
                required
                prop="homeEnable"
                :rules="[
                  {
                    required: true,
                    message: '是否应用于首页必填'
                  }
                ]"
              >
                <a-switch v-model="form.homeEnable" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item
                label="分类页"
                required
                prop="categoryEnable"
                :rules="[
                  {
                    required: true,
                    message: '是否应用于分类页必填'
                  }
                ]"
              >
                <a-switch v-model="form.categoryEnable" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item
                label="商品列表页"
                required
                prop="productListEnable"
                :rules="[
                  {
                    required: true,
                    message: '是否应用于商品列表页必填'
                  }
                ]"
              >
                <a-switch v-model="form.productListEnable" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item
                label="自定义页"
                required
                prop="pageEnable"
                :rules="[
                  {
                    required: true,
                    message: '是否应用于自定义页必填'
                  }
                ]"
              >
                <a-switch v-model="form.pageEnable" />
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-form-model-item
            label="文本内容"
            prop="text"
            :rules="[
              {
                required: true,
                message: '文本内容必填'
              }
            ]"
          >
            <a-textarea
              :maxLength="500"
              placeholder="请填写复制的文本内容"
              v-model="form.text"
              :auto-size="{ minRows: 4, maxRows: 10 }"
            />
          </a-form-model-item>
        </a-form-model>

        <div class="footer">
          <a-button type="primary" html-type="submit" @click="submitForm">
            保存
          </a-button>
          <a-button style="margin-left: 10px" @click="cancel">
            取消
          </a-button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

import textMixin from "@/mixins/text";

const ClipboardSvc = require("@/service/clipboard");

export default {
  name: "Clipboard",
  data() {
    return {
      form: {
        enabled: false,
        homeEnable: false,
        categoryEnable: false,
        productListEnable: false,
        pageEnable: false,
        text: ""
      }
    };
  },

  mixins: [textMixin],

  methods: {
    async submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            await ClipboardSvc.createOrUpdate(this.form);
            this.$message.info("保存成功");
          } catch (err) {
            console.error(err);
            this.$message.error("保存失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },
    cancel() {
      this.$router.replace({ name: "Vip" });
    }
  },

  async mounted() {
    try {
      let res = await ClipboardSvc.get();
      this.form = {
        enabled: !!res.enabled,
        homeEnable: !!res.homeEnable,
        categoryEnable: !!res.categoryEnable,
        productListEnable: !!res.productListEnable,
        pageEnable: !!res.pageEnable,
        text: res.text || ""
      };
    } catch (err) {
      console.error(err);
      this.$message.error("加载失败，错误：" + err.message);
    }
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-clipboard {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .adv-form {
    padding: 14px;
    margin-bottom: 10px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;

    .ant-form {
      max-width: 600px;
    }

    .day-input-wrap {
      display: flex;
      align-items: center;

      .ant-input-affix-wrapper {
        width: 100px;
        margin: 0 5px;
      }
    }
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #fff;
    padding: 10px;
    text-align: center;
  }
}
</style>
