import request from "./request";

/**
 * 剪贴板
 */

/**
 * 查询
 */
export const get = () => request.get("/clip/");

/**
 * 编辑
 */
export const createOrUpdate = data => request.post("/clip/", data);
